@font-face {
  font-family: 'Nexa Bold';
  src: local('Nexa - Bold'), url(./fonts/Nexa - Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Nexa Light';
  src: local('Nexa - Light'), url(./fonts/Nexa - Light.otf) format('opentype');
}

@font-face {
  font-family: 'Portico';
  src: local('Portico'), url(./fonts/Portico.otf) format('opentype');
}

@font-face {
  font-family: 'Portico Outline';
  src: local('Portico Outline'), url(./fonts/Portico Outline.otf) format('opentype');
}

body {
  margin: 0;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
